<template>
    <div class="animated fadeIn">
        <b-card>
            <div slot="header" no-body>
        <span class="d-flex w-100 justify-content-between">
          <span>
            <h5 style="margin-bottom: 0"><b>{{ constellationInfo.constellationname }} Permissions</b></h5>
          </span>
          <span style="display: inline-block; white-space: nowrap">
            <fa-icon v-if="permissionsSelected.length !== 0 && (usersSelected.length !== 0 || groupsSelected.length !== 0)"
                     :icon="['fas', 'trash']" style="padding-left: 10px" @click="deleteSelected"/>
            <fa-icon v-if="permissionsSelected.length !== 0 && (usersSelected.length !== 0 || groupsSelected.length !== 0)"
                     :icon="['fas', 'square-plus']" style="padding-left: 10px" @click="addSelected"/>
          </span>
        </span>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <v-client-table style="width: 100%" ref="userTable" :columns="userColumns" :data="users"
                                    :options="userOptions" :theme="theme" class="dataTable">
                        <b-form-checkbox slot-scope="props" slot="select" v-model="usersSelected"
                                         :value="props.row._id"></b-form-checkbox>
                        <div slot="h__select">
                            <b-form-checkbox type="checkbox" class="check-all" v-model='userAllMarked'
                                             @change="usersSelected = unmarkAndMarkAll(usersSelected, userAllMarked)"/>
                        </div>
                    </v-client-table>
                    <br>
                </div>
                <div class="col">
                    <v-client-table style="width: 100%" ref="groupTable" :columns="groupColumns" :data="groups"
                                    :options="groupOptions" :theme="theme" class="dataTable">
                        <b-form-checkbox slot-scope="props" slot="select" v-model="groupsSelected"
                                         :value="props.row._id"></b-form-checkbox>
                        <div slot="h__select">
                            <b-form-checkbox type="checkbox" class="check-all" v-model='groupAllMarked'
                                             @change="groupsSelected = unmarkAndMarkAll(groupsSelected, groupAllMarked)"/>
                        </div>
                    </v-client-table>
                    <br/>
                </div>
            </div>
            <v-client-table style="width: 100%" @row-click="rowClicked" ref="permissionsTable"
                            :columns="permissionsColumns" :data="permissions" :options="permissionsOptions"
                            :theme="theme" class="dataTable">
                <b-form-checkbox slot-scope="props" slot="select" v-model="permissionsSelected"
                                 :value="props.row._id"></b-form-checkbox>
                <div slot="h__select">
                    <b-form-checkbox type="checkbox" class="check-all" v-model='permissionsAllMarked'
                                     @change="permissionsSelected = unmarkAndMarkAll(permissionsSelected, permissionsAllMarked)"/>
                </div>
                <div slot="groups" slot-scope="props">
                    <div v-for="(group, index) in props.row.groups" :key="index">
                        <p style="line-height: 1;margin-bottom: 0; display:inline">{{ group.name }}</p>
                        <p v-if="props.row.groups.length < index - 1">, </p>
                    </div>
                </div>
                <div slot="usernames" slot-scope="props">
                    <div v-for="(user, index) in props.row.usernames" :key="index">
                        <p style="line-height: 1;margin-bottom: 0; display:inline">{{ user.name }}</p>
                        <p v-if="props.row.usernames.length < index - 1">, </p>
                    </div>
                </div>
            </v-client-table>
            <b-tooltip v-if="tooltipRender" ref="tooltip" :target="target" :title="tooltipTitle"
                       triggers=""></b-tooltip>
        </b-card>
    </div>
</template>

<script>
import iss from '../../../services/iss.js';
import constellation from '../../../services/constellation.js';
import axios from 'axios';
import Vue from 'vue';
import {ClientTable} from 'vue-tables-2';
import constellationStore from "@/store/constellationStore";
import PubSub from 'pubsub-js';

Vue.use(ClientTable)

export default {
    props: ["constellationId"],
    data: () => {
        return {
            pubsub: null,
            tooltipTitle: "",
            tooltipRender: true,
            target: '',
            permissions: [],
            users: [],
            groups: [],
            constellationInfo: {},
            permissionsSelected: [],
            usersSelected: [],
            groupsSelected: [],
            errorMsg: {method: "", status: "", statusText: ""},

            //Data Table
            useVuex: false,
            theme: 'bootstrap4',
            template: 'default',
            //Data Table For User
            userAllMarked: false,
            userColumns: ['select', '_id', 'username', 'type'],
            userOptions: {
                orderBy: {column: 'username', ascending: true},
                headings: {
                    _id: 'User ID',
                    username: 'Username',
                    type: 'User Type',
                    select: ' '
                },
                sortable: ['_id', 'username', 'type'],
                filterable: ['_id', 'username', 'type'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            //Data Table For Group
            groupAllMarked: false,
            groupColumns: ['select', '_id', 'groupname'],
            groupOptions: {
                orderBy: {column: 'groupname', ascending: true},
                headings: {
                    _id: 'Group ID',
                    groupname: 'Group name',
                    select: ' '
                },
                sortable: ['_id', 'groupname'],
                filterable: ['_id', 'groupname'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            //Data Table For Permissions
            permissionsAllMarked: false,
            permissionsColumns: ['select', 'url', 'request', 'groups', 'usernames'],
            permissionsOptions: {
                orderBy: {column: 'url', ascending: true},
                headings: {
                    url: 'Permission URL',
                    request: 'Request Type',
                    groups: 'Groups',
                    usernames: 'Usernames',
                    select: ' ',
                },
                sortable: ['url', 'request', 'groups', 'usernames'],
                filterable: ['url', 'request', 'groups', 'usernames'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
        }
    },
    created() {
        iss.getUsersInGroup().then(response => {
            this.users = response.data;
            iss.getAllGroups().then(response => {
                this.groups = response.data;
                constellationStore.dispatch('getConstellation', this.constellationId).then(response => {
                    this.constellationInfo = response;
                    this.listPermissions();
                    this.pubsub = PubSub.subscribe('constellationupdate-' + this.constellationId, (msg, data) => {
                        this.constellationInfo = data;
                        this.listPermissions();
                    });
                }).catch(error => {
                    this.$mToast({
                        title: 'Error',
                        text: "Constellation info couldn't be retrieved",
                        style: 'error'
                    });
                });
            }).catch(error => {
                this.$mToast({
                    title: 'Error',
                    text: "Group info couldn't be retrieved",
                    style: 'error'
                });
            });
        }).catch(error => {
            this.$mToast({
                title: 'Error',
                text: "User info couldn't be retrieved",
                style: 'error'
            });
        });
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.pubsub);
    },
    methods: {
        unmarkAndMarkAll(selected, allMarked) {
            if (allMarked === false) {
                let temp = [];
                if (selected === this.permissionsSelected) {
                    if (this.$refs.permissionsTable.allFilteredData) {
                        this.$refs.permissionsTable.allFilteredData.forEach(function (item) {
                            temp.push(item._id);
                        });
                    }
                } else if (selected === this.groupsSelected) {
                    if (this.$refs.groupTable.allFilteredData) {
                        this.$refs.groupTable.filteredData.forEach(function (item) {
                            temp.push(item._id);
                        });
                    }
                } else {
                    if (this.$refs.userTable.allFilteredData) {
                        this.$refs.userTable.allFilteredData.forEach(function (item) {
                            temp.push(item._id);
                        });
                    }
                }
                return (temp)
            } else {
                return ([])
            }
        },
        listPermissions() {
            constellation.getPermissions(this.constellationInfo.url + ':' + this.constellationInfo.port).then(response => {
                this.permissions = response.data;
                this.permissions.forEach(permission => {
                    if (permission.usernames) {
                        permission.usernames = permission.usernames.reduce((res, userId) => {
                            let user = this.users.find(_ => _._id === userId);
                            if (user && user.username) {
                                res.push({_id: userId, name: user.username});
                            }
                            return res;
                        }, [])
                    }
                    if (permission.groups) {
                        permission.groups = permission.groups.reduce((res, groupId) => {
                            let group = this.groups.find(_ => _._id === groupId);
                            if (group && group.groupname) {
                                res.push({_id: groupId, name: group.groupname});
                            }
                            return res;
                        }, [])
                    }
                })
            }).catch(error => {
                this.$mToast({
                    title: 'Error',
                    text: "Permissions couldn't be retrieved",
                    style: 'error'
                });
            });
        },
        addSelected() {
            let data = {
                usernames: this.usersSelected,
                groups: this.groupsSelected,
                permissions: this.permissionsSelected
            }
            constellation.putPermissions(this.constellationInfo.url + ':' + this.constellationInfo.port, data).then(() => {
                this.groupsSelected = [];
                this.usersSelected = [];
                this.permissionsSelected = [];
                this.listPermissions();
                this.$mToast({
                    title: 'Success',
                    text: 'Permissions Added',
                    style: 'success'
                });

            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Permissions couldn't be added: " + error.response.statusText,
                    style: 'error'
                });
            });
        },
        deleteSelected() {
            let data = {
                usernames: this.usersSelected,
                groups: this.groupsSelected,
                permissions: this.permissionsSelected
            }
            constellation.deletePermissions(this.constellationInfo.url + ':' + this.constellationInfo.port, data).then(() => {
                this.groupsSelected = [];
                this.usersSelected = [];
                this.permissionsSelected = [];
                this.listPermissions();
                this.$mToast({
                    title: 'Success',
                    text: 'Permissions Deleted',
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Permissions couldn't be deleted: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        rowClicked(item) {
            //ctrl click to make tooltip appear with id
            if (item.event.ctrlKey) {
                this.tooltipRender = false;
                this.tooltipTitle = item.row._id;
                item.event.target.id = item.row._id;
                this.target = item.row._id;
                //restart tooltip so it can map to its new position
                Vue.nextTick().then(() => {
                    this.tooltipRender = true;
                    Vue.nextTick().then(() => {
                        this.$refs.tooltip.$emit('open')
                        setTimeout(function () {
                            //make the tooltip disapear automatically
                            this.$refs.tooltip.$emit('close');
                        }.bind(this), 4000)
                    });
                });
            }
        },
    }
}
</script>
